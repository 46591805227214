<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
div {
    width: 100vw;
    height: 100vh;
    margin-top: 50px;
    border-bottom: 1px solid #d3e7ff;
}
</style>