<template>
  <div class="page">
    <transition>
        <div class="product-page" v-if="show_prooduct">
            <block>
                <Product :soft="soft"></Product>
            </block>
        </div>
        <div class="cabinet-page" v-else-if="show_cabinet">
            <block>
                <Cabinet :soft="soft"></Cabinet>
            </block>
        </div>
        <div class="main-page" v-else>
            <block>
                <MainPage :soft="soft"></MainPage>
            </block>
            <block>
                <Safe :soft="soft"></Safe>
            </block>
            <block>
                <Faster :soft="soft"></Faster>
            </block>
            <block>
                <Contact :soft="soft"></Contact>
            </block>
        </div>
    </transition>
    
    
    <OrderModal v-if="show_OrderModal" :soft="soft" @close="show_OrderModal = false"></OrderModal>
    <LoginModal v-if="show_LoginModal" :soft="soft" @close="show_LoginModal = false"></LoginModal>
  </div>
</template>

<script>

import block from './components/Block.vue';
import MainPage from './components/Main.vue';
import Faster from './components/Faster.vue';
import Safe from './components/Safe.vue';
import Contact from './components/Contact.vue';
import Product from './components/Product.vue';
import Cabinet from './components/cabinet/Cabinet.vue';
import OrderModal from './components/OrderModal.vue';
import LoginModal from './components/LoginModal.vue';

export default {
    data(){
        return{
            port: 3010,
            show_OrderModal: false,
            show_LoginModal: false,
            show_prooduct: false,
            show_cabinet: false,
            auth: false,
            user: {},
            socket: {},
            soft: {
                order_type: '',
                err: {
                    flag: false,
                    type: '',
                    msg: ''
                },
                showModal: (order_type)=>{
                    this.soft.order_type = order_type;
                    switch(order_type){
                        case 'web': 
                            this.show_OrderModal = true;
                        break
                        case 'cloud': 
                            this.show_OrderModal = true;
                        break
                        case 'login': 
                            this.show_LoginModal = true;
                        break
                            
                    }
                },

                checkAuth: ()=>{
                    return this.auth;
                },

                getUser: ()=>{
                    return this.user;
                },

                send: (data)=>{
                    data.session_id = this.soft.getStorage('session_id')
                    data = JSON.stringify(data);
                    this.socket.send(data);
                    console.log('sended');
                    
                },

                setStorage: function(name, data){
                    data = JSON.stringify(data);
                    localStorage.setItem(name, data);
                },
                getStorage: function(name) {
                    let data = localStorage.getItem(name);
                    return data ? JSON.parse(data) : null;
                },

                goto(to){
                    window.location.hash = to;
                },

                getErr: function(){
                    return this.err.errMsg
                },

                logOff: ()=>{
                    this.auth = false;
                    localStorage.clear();
                    window.location.hash = '';
                }
            }
        }
    },
    components: {
        block,
        MainPage,
        Faster,
        Safe,
        Contact,
        Product,
        Cabinet,
        OrderModal,
        LoginModal,
    },
    methods: {
        Connect(){
            // var host = window.location.hostname;
            var protocol = window.location.protocol;
            // console.log(protocol);
            var host;
            if(protocol != "https:"){
                host = "ws://"+window.location.hostname+':'+this.port;
            }else{
                host = "wss://"+window.location.hostname+':'+this.port;
            }
            
            let session_id = this.soft.getStorage('session_id');
            console.log(session_id);
            
            
            this.socket = new WebSocket(host);
            this.socket.onopen = ()=>{
                console.log('connected');
                let data = {act: 'check_session', base: 'session'}
                if(session_id != 'undefined' && session_id != ''){
                    data.session = session_id
                }
                this.soft.send(data)
                this.socket.onmessage = (e)=>{
                    var inMsg = JSON.parse(e.data);
                    this.onMessage(inMsg);
                }
                this.socket.onclose = (e)=>{
                    if(!e.wasClean){
                        setTimeout(()=>{
                            this.Connect();
                        }, 3000);
                    }
                }
            }
            this.socket.onerror = (e)=>{
                setTimeout(()=>{
                    console.log("Ошибка " + e.message);
                    this.Connect();
                }, 3000);
            }
        },
        onMessage(data){
            switch(data.act){
                case 'connect':
                    this.soft.setStorage('session_id', data.result.session_id);
                break;

                case 'auth':
                    this.auth = true;
                    this.user = data.result;
                    this.soft.setStorage('auth', true);
                    this.soft.setStorage('user', data.result);
                break

                case 'err':
                    this.Err( true, 'err', data.result);
                break;
            }
        },
        showPage(hash){
            switch(hash){
                case '':
                    this.show_prooduct = false;
                    this.show_cabinet = false;
                break;
                case '/products': 
                    this.show_prooduct = true;
                break;
                case '/cabinet': 
                    this.show_cabinet = true;
                break;
                default:
                    this.show_prooduct = false;
                break;
            }
        },
        Err(flag, type, msg){
            this.soft.err.flag = flag;
            this.soft.err.type = type?type:'unknown';
            this.soft.err.errMsg = msg.err?msg.err:'Неизвестная ошибка';
        },
    },
    mounted(){ 
        console.log('AUTH: '+this.soft.getStorage('session_id'));
        this.Connect(false);
        
        if(this.soft.getStorage('auth')){
            this.auth = true;
            this.user = this.soft.getStorage('user');
        }
        
        this.showPage(window.location.hash.replace('#', ''));
        window.addEventListener('hashchange', () => {
            this.showPage(window.location.hash.replace('#', ''));
		})
        
    }
}
</script>

<style>

* {
    margin: 0;
    padding: 0;
}

.btn {
    margin-top: 30px;
    border: 3px solid #82BBFF;
    border-radius: 8px;
    padding: 6px 30px;
    color: #1F436F;
}

.btn:hover {
    border-color: #275792;
}

.arrow {
    padding: 0;
    padding-top: 60px;
    left: -10px;
    margin: 0 140px;
}

.blue {
    background-color: #82BBFF;
    color: #fff !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.list-group.hover .list-group-item:hover:not(.header) {
    background-color: rgb(231, 231, 231);
}

.list-group.pointer {
    cursor: pointer;
}

</style>
