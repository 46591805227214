<template>
    <div class="block">
        <div class="row top-block">
            <div class="col-sm-2 logo" @click="main()">
                <img src="../../assets/Logo.svg" />
            </div>
            <div class="col-sm-8 menu">
                <span>Мои Услуги</span>
                <span>Финансы</span>
            </div>
            <div class="col-sm-2">
                <button class="btn" @click="soft.logOff()">выйти</button>
            </div>
        </div>
        <div class="row main-block">
            <div class="col-10">
                <div>
                    {{  soft.getUser().name }}
                </div>
                <transition>
                    <Services :soft="soft"></Services>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import Services from './Services.vue';
export default {
    props: ['soft'],
    data(){
        return{
        }
    },
    components: {
        Services,
    },
    methods: {
        main(){
            this.soft.goto('')
        }
    },
    computed: {
    },
    mounted(){
    }
}
</script>

<style scoped>
.top-block {
    width: 100vw;
}

.top-block .logo {
    text-align: right;
    cursor: pointer;
}

.top-block .logo img {
    width: 135px;
}

.top-block .menu {
    margin-top: 45px;
}

.top-block .menu span{
    margin-right: 25px;
    cursor: pointer;
}

.main-block {
    margin-top: 20px;
    padding-left: 200px;
}





</style>