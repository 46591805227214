<template>
        <div class="col-sm-3 login pointer" v-if="soft.checkAuth()" @click="soft.goto('/cabinet')">
            <img  class="pointer" src="../assets/Auth.svg"/>
            <span class="user text-truncate">{{ soft.getStorage('user').username }}</span>
        </div>

        <div class="col-sm-3 login" v-else  @click="soft.showModal('login')">
            <img  class="pointer" src="../assets/Login.png"/>
        </div>
</template>

<script>
export default {
    props: ['soft']
}
</script>

<style scoped>
.login {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.login .user{
    margin-left: 16px;
    /* color: ; */
}

</style>

