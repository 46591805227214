<template lang="html">
    <transition name="modal">
        <div id="modal-mask" class="modal-m" @click.self="close">
            <div class="modal-wr">
                <div class="modal-container">
                    <button type="button" class="close" @click="close"  data-dismiss="modal">&times;</button>
                    <div class="modal-body small" v-if="!show_registration" @keyup.enter="auth()">
                        <div class="d-flex justify-content-center">
                            <img src="../assets/Logo.svg" >
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center w-100">
                            <div class="form-group">
                                <label for="" class="col-sm-4 col-form-label col-form-label-sm">Логин:</label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control form-control-sm" v-model="login" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="" class="col-sm-4 col-form-label col-form-label-sm">Пароль:</label>
                                <div class="col-sm-12">
                                    <input type="password" class="form-control form-control-sm" v-model="password" />
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <span class="badge"><a @click="show_registration = true">Регистрация</a></span>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button class="btn" @click="auth()">Войти</button>
                        </div>
                    </div>
                    <div class="modal-body small" v-else  @keyup.enter="register()">
                        <div class="d-flex justify-content-center">
                            <img src="../assets/Logo.svg" >
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center w-100">
                            <div class="form-group w-75">
                                <label for="" class="col-sm-12 col-form-label col-form-label-sm">Название компании/ФИО:</label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control form-control-sm" v-model="partner.name" />
                                </div>
                            </div>
                            <div class="form-group w-75">
                                <label for="" class="col-sm-12 col-form-label col-form-label-sm">@Email:</label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control form-control-sm" v-model="partner.email" />
                                </div>
                            </div>
                            <div class="form-group w-75">
                                <label for="" class="col-sm-12 col-form-label col-form-label-sm">Пароль:</label>
                                <div class="col-sm-12">
                                    <div class="input-group flex-nowrap">
                                        <input type="password" v-if="!show_password" class="form-control form-control-sm" v-model="partner.password" aria-describedby="addon-wrapping"/>
                                        <input type="text" v-else class="form-control form-control-sm" v-model="partner.password" />
                                        <span class="input-group-text bi bi-eye-slash" v-if="!show_password"  @click="toggleShowPassword()"></span>
                                        <span class="input-group-text bi bi-eye" v-else  @click="toggleShowPassword()"></span>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="form-group w-75">
                                <label for="" class="col-sm-12 col-form-label col-form-label-sm">Повторить пароль:</label>
                                <div class="col-sm-12">
                                    <div class="input-group flex-nowrap">
                                        <input type="password" v-if="!show_copyPassword" class="form-control form-control-sm" @input="checkPassword()" v-model="copy_password" />
                                        <input type="text" v-else class="form-control form-control-sm" @input="checkPassword()" v-model="copy_password" />
                                        <span class="input-group-text bi bi-eye-slash" v-if="!show_copyPassword"  @click="toggleShowCopyPassword()"></span>
                                        <span class="input-group-text bi bi-eye" v-else  @click="toggleShowCopyPassword()"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <span class="badge"><a @click="show_registration = false">Уже есть аккаунт.</a></span>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button class="btn" @click="register()">Зарегистрировать</button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center m-2 text-danger" v-if="soft.err.flag">
                        <span>{{ err }}</span>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: ['soft'],
    data(){
        return {
            login: '',
            password: '',
            show_registration: false,
            partner: {
                name: '',
                email: '',
                password: '',
            },

            copy_password: '',
            show_password: false,
            show_copyPassword: false,
        }
    },
    
    watch: {
        partner: {
            handler: function(){
                this.soft.Err(false)
            },
            deep: true
        },
    },
    methods: {
        close(){
            this.$emit('close');
        },
        auth(){
            this.soft.send({act: 'login', base: 'login', login: this.login, password: this.password});
            this.close();
        },
        register(){
            this.soft.send({act: 'registration', base: 'registration', query: this.partner});
        },
        checkPassword(){
            if(this.partner.password === this.copy_password){
                console.log('success');
            }
        },
        toggleShowPassword(){
            this.show_password = !this.show_password;
        },
        toggleShowCopyPassword(){
            this.show_copyPassword = !this.show_copyPassword;
        }
    },
    computed: {
        err(){
            return this.soft.getErr();
        }
    },
    mounted(){
    }
}
</script>

<style lang="css" scoped>
.modal-m {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, .5);
display: flex;
justify-content: center;
align-items: flex-start;
transition: opacity .3s ease;
}

.modal-wr {
    margin-top: 100px;
    margin-bottom: 110px;
}

.modal-container {
width: 600px;
overflow: hidden;
margin: 0px auto;
padding: 10px 20px;
background-color: #fff;
border-radius: 6px;
box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
transition: all .3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.sticky-bottom {
    position: relative;
    bottom: 0px;
}

img {
    width: 200px;
}

</style>
